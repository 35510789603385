@import "https://fonts.googleapis.com/css?family=Roboto";
body {
  background: #e2e2e2;
  font-family: Roboto, sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

i {
  margin-right: 10px;
}

.navbar-logo {
  color: #fff;
  padding: 15px;
}

.navbar-mainbg {
  padding: 0;
  background-color: #5161ce !important;
}

.navbarSupportedContent {
  position: relative;
  overflow: hidden;
}

.navbarSupportedContent ul {
  margin: 0;
  padding: 0;
}

.navbarSupportedContent ul li a i {
  margin-right: 10px;
}

.navbarSupportedContent li {
  float: left;
  list-style-type: none;
}

.navbarSupportedContent ul li a {
  color: #ffffff80;
  padding: 20px;
  font-size: 15px;
  text-decoration: none;
  transition-duration: .6s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  display: block;
  position: relative;
}

.navbarSupportedContent > ul > li.active > a {
  color: #5161ce;
  background-color: #0000;
  transition: all .7s;
}

.navbarSupportedContent a:not(:only-child):after {
  content: "";
  vertical-align: middle;
  padding-right: 3px;
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  transition: all .5s;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 20px;
}

.navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}

@media (width >= 992px) {
  .navbar-expand-custom {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-custom .navbar-toggler {
    display: none;
  }

  .navbar-expand-custom .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }
}

@media (width <= 991px) {
  .navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
}

.navbar-nav .nav-item.active {
  color: #5161ce;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  min-height: 100vh;
  padding: 60px 0;
}

#carousel-thumb .carousel-item:before {
  content: "";
  z-index: 999;
  background: #0000003d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#carousel-thumb .carousel-indicators li {
  width: 100px;
}

#carousel-thumb .carousel-indicators {
  bottom: 40px;
}

#carousel-thumb .carousel-indicators li img {
  border: 2px solid #e2e2e2;
}

.heading {
  text-align: center;
  margin-top: 20px;
  text-decoration: underline;
}

.imw {
  width: 65%;
  position: relative;
}

.product {
  margin-top: 30px;
  margin-bottom: 30px;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  opacity: 0;
  background-color: #000;
  width: 100%;
  height: 60%;
  transition: all .5s;
  position: absolute;
  inset: 9px 0 0;
}

.ccc:hover .overlay {
  opacity: .7;
}

.text {
  color: #fff;
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cc1 {
  color: #000;
  background: #fff;
  border: 1px solid #000;
  border-radius: 7px;
  width: 82%;
  height: 40px;
  padding: 4px 8px;
}

@media only screen and (width <= 553px) {
  .card {
    margin-top: 25px;
  }
}

.card {
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px #888;
}

.error {
  color: red;
}

.copyright-background {
  background-color: #0000000d;
}

.carousel-style {
  object-fit: strech;
  height: 700px;
}

.navbar-logo {
  font-weight: 900;
}

a {
  color: #5161ce;
  text-decoration: none;
}
/*# sourceMappingURL=index.225d5e62.css.map */
