@import url('https://fonts.googleapis.com/css?family=Roboto');

body{
	font-family: 'Roboto', sans-serif;
	background:#e2e2e2;
}
* {
	margin: 0;
	padding: 0;
}
i {
	margin-right: 10px;
}
/*----------bootstrap-navbar-css------------*/
.navbar-logo{
	padding: 15px;
	color: #fff;
}
.navbar-mainbg{
	background-color: #5161ce !important;
	padding: 0px;
}
.navbarSupportedContent{
	overflow: hidden;
	position: relative;
}
.navbarSupportedContent ul{
	padding: 0px;
	margin: 0px;
}
.navbarSupportedContent ul li a i{
	margin-right: 10px;
}
.navbarSupportedContent li {
	list-style-type: none;
	float: left;
}
.navbarSupportedContent ul li a{
	color: rgba(255,255,255,0.5);
    text-decoration: none;
    font-size: 15px;
    display: block;
    padding: 20px 20px;
    transition-duration:0.6s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}
.navbarSupportedContent>ul>li.active>a{
	color: #5161ce;
	background-color: transparent;
	transition: all 0.7s;
}
.navbarSupportedContent a:not(:only-child):after {
	content: "\f105";
	position: absolute;
	right: 20px;
	top: 10px;
	font-size: 14px;
	font-family: "Font Awesome 5 Free";
	display: inline-block;
	padding-right: 3px;
	vertical-align: middle;
	font-weight: 900;
	transition: 0.5s;
}
.navbarSupportedContent .active>a:not(:only-child):after {
	transform: rotate(90deg);
}



@media(min-width: 992px){
	.navbar-expand-custom {
	    -ms-flex-flow: row nowrap;
	    flex-flow: row nowrap;
	    -ms-flex-pack: start;
	    justify-content: flex-start;
	}
	.navbar-expand-custom .navbar-nav {
	    -ms-flex-direction: row;
	    flex-direction: row;
	}
	.navbar-expand-custom .navbar-toggler {
	    display: none;
	}
	.navbar-expand-custom .navbar-collapse {
	    display: -ms-flexbox!important;
	    display: flex!important;
	    -ms-flex-preferred-size: auto;
	    flex-basis: auto;
	}
}


@media (max-width: 991px){
	.navbarSupportedContent ul li a{
		padding: 12px 30px;
	}
}



.navbar-nav .nav-item.active {
    background-color: white;
    color: #5161ce;
    border-radius: 10px; /* Adjust the border-radius as needed */
    position: relative; /* Ensure the child .hori-selector is positioned relative to this element */
    overflow: hidden; /* Hide overflow for the child .hori-selector */
}



/* slider */

img {
    max-width: 100%;
    height: auto;
}
        section {
            padding: 60px 0;
            min-height: 100vh;
        }
#carousel-thumb .carousel-item:before {
   content: "";
   position: absolute;
   z-index: 999;
   background: #0000003d;
   height: 100%;
   width: 100%;
   left: 0;
   top: 0;
}
#carousel-thumb .carousel-indicators li {
    width: 100px;
}

#carousel-thumb .carousel-indicators {
    bottom: 40px;
}

#carousel-thumb .carousel-indicators li img {
    border: 2px solid #e2e2e2;
}





/* product grid */

.heading{
    text-align: center;
    margin-top: 20px;
    text-decoration: underline;
}
.imw{
    width: 65%;
    position: relative;
}
.product{
    margin-top: 30px;
    margin-bottom: 30px;
}

.image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 9;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000;
  }
  
  .ccc:hover .overlay {
    opacity: 0.7;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .cc1{
    width: 82%;
    background: #fff;
    color: #000;
    padding: 4px 8px;
    border: 1px solid #000;
    height: 40px;
    border-radius: 7px;
  }
  @media only screen and (max-width: 553px) {
    .card{
        margin-top: 25px;
    }
  }
  .card{
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px #888888;
  }
  

  .error{
    color:red;
  }

  .copyright-background{
    background-color: rgba(0, 0, 0, 0.05);
  }

  .carousel-style{
    height:700px;
    object-fit: strech;
  }

  .navbar-logo{
    font-weight: 900;
  }

  a{
    text-decoration: none;
    color: #5161ce;
  }



  /*  */